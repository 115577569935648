<template>
  <div class="reviews-tourists">
    <div class="reviews-tourists__wrapper">
      <Row justify="between">
        <Column class="reviews-tourists__add">
          <h2 class="reviews-tourists__add-title">{{ $t('reviews.reviews') }}</h2>
          <Button color="main"
                  v-on:click.native="$openPopupAddFact">{{ $t('reviews.add_fact') }}
          </Button>
        </Column>
        <Column class="reviews-tourists__slider-holder">
          <div class="reviews-tourists__slider">
            <ReviewTourists :key="fact.id"
                            :params="fact"
                            v-for="fact in facts"/>
          </div>
          <div class="reviews-tourists__navigation-button reviews-tourists__next"
               v-on:click="next">
            <Icon class="reviews-tourists__navigation-button-svg"
                  viewport="0 0 25 18"
                  xlink="arrow-slider-preview"/>
          </div>
          <div class="reviews-tourists__navigation-button reviews-tourists__preview"
               v-on:click="preview">
            <Icon class="reviews-tourists__navigation-button-svg"
                  viewport="0 0 25 18"
                  xlink="arrow-slider-next"/>
          </div>
        </Column>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReviewsTourists',
  data() {
    return {
      temp: '',
    };
  },
  created() {
    if (this.$facts.data.length === 0) this.$store.dispatch('GET_FACTS_FROM_SERVER');
  },
  computed: {
    facts() {
      return this.$facts.data;
    },
  },
  methods: {
    getFirst() {
      this.temp = this.facts.find((item, index) => (index === 0 ? item : ''));
    },
    getLast() {
      this.temp = this.facts.find((item, index, array) => (index === array.length - 1 ? item : ''));
    },
    next() {
      this.getFirst();
      this.$store.commit('REMOVE_FIRST_FACT');
      this.$nextTick(() => {
        this.$store.commit('ADD_LAST_FACT', this.temp);
      });
    },
    preview() {
      this.getLast();
      this.$store.commit('REMOVE_LAST_FACT');
      this.$nextTick(() => {
        this.$store.commit('ADD_FIRST_FACT', this.temp);
      });
    },
  },
};
</script>
